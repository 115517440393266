:root {
  // Deprecated
  --tui-heading-font: 'Poppins';
  --tui-text-font: 'Poppins';
  // Fonts
  --tui-font-heading: var(--tui-heading-font);
  --tui-font-heading-1: 700 4rem/4.5rem var(--tui-font-heading);
  --tui-font-heading-2: bold 2.25rem/3.5rem var(--tui-font-heading);
  --tui-font-heading-3: bold 2.25rem/2.5rem var(--tui-font-heading);
  --tui-font-heading-4: bold 1.75rem/2rem var(--tui-font-heading);
  --tui-font-heading-5: 600 1.35rem/1.85rem var(--tui-font-heading);
  --tui-font-heading-6: bold 1rem/1.25rem var(--tui-font-heading);
  --tui-font-heading-7: 700 0.625rem/0.75rem var(--tui-font-heading);
  --tui-font-heading-8: 700 1rem/1.5rem var(--tui-font-heading);
  --tui-font-text: var(--tui-text-font);

  --tui-font-text-xl: normal 1.1875rem/1.75rem var(--tui-font-text);
  --tui-font-text-l: normal 1rem/1.5rem var(--tui-font-text);
  --tui-font-text-m: normal 0.9375rem/1.5rem var(--tui-font-text);
  --tui-font-text-s: normal 0.8125rem/1.25rem var(--tui-font-text);
  --tui-font-text-xs: normal 0.6875rem/1rem var(--tui-font-text);

  --tui-radius-xs: 0.5rem;
  --tui-radius-s: 0.625rem;
  --tui-radius-m: 0.75rem;
  --tui-radius-l: 1rem;
  --tui-radius-xl: 1.25rem;

  --tui-height-xs: 1.5rem;
  --tui-height-s: 2rem;
  --tui-height-m: 2.5rem;
  --tui-height-l: 3rem;

  // Input padding
  --tui-padding-s: 0.75rem;
  --tui-padding-m: 0.75rem;
  --tui-padding-l: 1rem;
  // Misc
  --tui-duration: 0.3s;
  // Misc
  --tui-duration: 0.3s;

  // Shadows
  --tui-shadow-1: 0px 2px 3px 0px #0000001a;

  // Special colors
  --tui-disabled-opacity: 0.56;
  --tui-autofill: #fff5c0;
  // Base colors
  --tui-base-01: #fff; // background and icons on inverted background
  --tui-base-02: #f3f5f6; // secondary background, marker icons background
  --tui-base-03: #ededed; // basic border
  --tui-base-04: #e2e5e9; // hovered borders
  --tui-base-05: #b0b0b0; // interface icons
  --tui-base-06: #959595; // hovered interface icons
  --tui-base-07: #808080; // inverted background
  --tui-base-08: #333; // dark interface icons
  --tui-base-09: #000; // icons on inverted background
  --tui-base-10: #f9f9f9; // icons on inverted background
  --tui-primary: #2e4fff; // primary buttons, background
  --tui-primary-hover: #6c86e2; // primary buttons hover
  --tui-primary-active: #314692; // primary buttons hover
  --tui-primary-text: #fff; // text on primary background
  --tui-secondary: #ebefff; // inputs and secondary buttons
  --tui-secondary-hover: #dfe3f3; // inputs and secondary buttons hover
  --tui-secondary-active: #d8ddf2; // inputs and secondary buttons pressed
  --tui-accent: #f0047f; // fill and outline of accent elements
  --tui-accent-faded: #f3ddea; // fill and outline of accent elements
  --tui-accent-light: #fa4490; // fill and outline of accent elements
  --tui-accent-lighter: #fee6f3; // fill and outline of accent elements
  --tui-accent-01: #e96363; // fill and outline of accent elements
  --tui-accent-01-faded: #f7e4e4; // fill and outline of accent elements
  --tui-accent-01-light: #f26d6d; // fill and outline of accent elements
  --tui-accent-01-lighter: #f7e6e6; // fill and outline of accent elements
  --tui-accent-02: #ffd24d;
  --tui-accent-03: #292594;
  --tui-accent-04: #3631c5;
  --tui-accent-05: #600df2;
  --tui-accent-05-faded: #e4def6;
  --tui-accent-05-light: #975bff;
  --tui-accent-05-lighter: #f0e7fe;
  --tui-accent-07: #0ac2a3;
  --tui-accent-07-faded: #dcf0ee;
  --tui-accent-07-light: #23d6b7;
  --tui-accent-07-lighter: #e7f9f6;
  --tui-accent-08: #e9f5fc;
  --tui-accent-09: #2092df;
  --tui-accent-10: #1fceff;
  --tui-accent-11: #3cc98c;
  --tui-accent-12: #e743a0;
  --tui-accent-13: #e5e9ff;
  --tui-accent-13-hover: #e5e9ffaa;
  --tui-accent-14: #f45725;
  --tui-accent-15: #38339c;
  --tui-accent-16: #3c389c; // menu border
  --tui-accent-17: #ff0000; // notifications count badge bg
  --tui-accent-18: #ffc456; // Pro profile (filter)
  --tui-accent-19: #ff467e; // favourites
  --tui-accent-29: #efefef;
  --tui-accent-hover: #ff9a94; // accent elements hover
  --tui-accent-active: #e7716a; // accent elements pressed
  --tui-accent-text: #fff; // text on accent background
  --tui-selection: rgba(46, 79, 255, 0.32); // selected text background
  --tui-focus: rgba(51, 51, 51, 0.64); // focus ring color
  --tui-clear: rgba(0, 0, 0, 0.08); // translucent dark fill
  --tui-clear-disabled: rgba(0, 0, 0, 0.04); // translucent dark fill disabled
  --tui-clear-hover: rgba(0, 0, 0, 0.16); // translucent dark fill hover
  --tui-clear-active: rgba(0, 0, 0, 0.2); // translucent dark fill pressed
  --tui-clear-inverse: rgba(255, 255, 255, 0.16); // translucent light fill
  --tui-clear-inverse-hover: rgba(255, 255, 255, 0.24); // translucent light fill hover
  --tui-clear-inverse-active: rgba(255, 255, 255, 0.4); // translucent light fill pressed
  // Statuses
  --tui-error-fill: rgba(244, 87, 37, 1); // icons and decorative elements with error status
  --tui-error-bg: rgba(244, 87, 37, 0.12); // translucent error background
  --tui-error-bg-hover: rgba(244, 87, 37, 0.24); // translucent hover error background
  --tui-success-fill: rgba(74, 201, 155, 1); // icon and decorative elements with success status
  --tui-success-bg: rgba(74, 201, 155, 0.12); // translucent success background
  --tui-success-bg-hover: rgba(74, 201, 155, 0.24); // translucent success hover
  --tui-warning-fill: rgba(255, 199, 0, 1); // icon and decorative elements with warning status
  --tui-warning-bg: rgba(255, 199, 0, 0.12); // translucent warning background
  --tui-warning-bg-hover: rgba(255, 199, 0, 0.24); // translucent warning background
  --tui-info-fill: rgba(112, 182, 246, 1); // icon and decorative elements with info status
  --tui-info-bg: rgba(112, 182, 246, 0.12); // translucent info background
  --tui-info-bg-hover: rgba(112, 182, 246, 0.24); // translucent info background
  --tui-neutral-fill: rgb(121, 129, 140); // icon and decorative elements with neutral status
  --tui-neutral-bg: rgba(121, 129, 140, 0.12); // translucent info background
  --tui-neutral-bg-hover: rgba(121, 129, 140, 0.24); // translucent info background
  // Text
  --tui-text-01: #272e55;
  --tui-text-02: #1b1f3ba6;
  --tui-text-03: #0a1f5c;
  --tui-text-04: #bbc0d1;
  --tui-text-05: #6e7191;
  --tui-text-06: #5d74f5;
  --tui-link: #0a1f5c;
  --tui-link-hover: #2e4fff;
  --tui-positive: #3aa981;
  --tui-positive-hover: #7ac5aa;
  --tui-negative: #dd4c1e;
  --tui-negative-hover: #e38163;
  // Modifiers for dark background
  --tui-error-fill-night: rgba(255, 140, 103, 1);
  --tui-error-bg-night: rgba(244, 87, 37, 0.32);
  --tui-error-bg-night-hover: rgba(244, 87, 37, 0.4);
  --tui-success-fill-night: rgb(74, 201, 155);
  --tui-success-bg-night: rgba(74, 201, 155, 0.32);
  --tui-success-bg-night-hover: rgba(74, 201, 155, 0.4);
  --tui-warning-fill-night: rgb(255, 199, 0);
  --tui-warning-bg-night: rgba(255, 199, 0, 0.32);
  --tui-warning-bg-night-hover: rgba(255, 199, 0, 0.4);
  --tui-info-fill-night: rgb(112, 182, 246);
  --tui-info-bg-night: rgba(112, 182, 246, 0.32);
  --tui-info-bg-night-hover: rgba(112, 182, 246, 0.4);
  --tui-neutral-fill-night: rgb(149, 155, 164);
  --tui-neutral-bg-night: rgb(149, 155, 164, 0.32);
  --tui-neutral-bg-night-hover: rgb(149, 155, 164, 0.48);
  //
  --tui-text-01-night: rgba(255, 255, 255, 1);
  --tui-text-02-night: rgba(255, 255, 255, 0.72);
  --tui-text-03-night: rgba(255, 255, 255, 0.6);
  --tui-link-night: #6788ff;
  --tui-link-night-hover: #526ed3;
  --tui-positive-night: #44c596;
  --tui-positive-night-hover: #3aa981;
  --tui-negative-night: #ff8c67;
  --tui-negative-night-hover: #bb593a;
  //
  --tui-support-01: #a8cef1;
  --tui-support-02: #3682db;
  --tui-support-03: #8dda71;
  --tui-support-04: #34b41f;
  --tui-support-05: #e29398;
  --tui-support-06: #b8474e;
  --tui-support-07: #fcc068;
  --tui-support-08: #ff8a00;
  --tui-support-09: #dab3f9;
  --tui-support-10: #7b439e;
  --tui-support-11: #fee797;
  --tui-support-12: #fcbb14;
  --tui-support-13: #ea97c4;
  --tui-support-14: #bd65a4;
  --tui-support-15: #7fd7cc;
  --tui-support-16: #2fad96;
  --tui-support-17: #d4aca2;
  --tui-support-18: #9d6f64;
  --tui-support-19: #d2e9a2;
  --tui-support-20: #aadc42;
  --tui-support-21: #a0c5df;
  --tui-support-22: #3c7ba8;
  --tui-support-23: #f6f8f9;
  --tui-support-24: #d3d6e0;
  --tui-support-25: #f6f8f8ff;
  --tui-support-26: #f8faff;
  --tui-support-27: #eff2fe;
  --tui-support-28: #b8b7d0;
  --tui-support-29: #3934a7;
  --tui-support-30: #5752b4;

  --tui-cubic-bezier: cubic-bezier(0.26, 0.86, 0.09, 0.84);
  // Profile avatar - badge pro
  --tui-badgepro-text: #c97b26;
  --tui-badgepro-01: #ffe259;
  --tui-badgepro-02: #ffa751;
  // Partners
  --tui-oxford-bg: #133464;

  //  Others
  --credits-bg: rgba(255, 225, 188, 0.32);
  --credits-text: #f9c27e;
  --tui-info-bg-2: rgba(227, 241, 254, 1);
  --tui-error-bg-2: rgba(255, 234, 234, 1);
  --tui-error-text: rgba(255, 86, 86, 1);
  --tui-warning-badge-bg: #fff8e0;
  --tui-warning-badge-fill: var(--tui-accent-02);
  --tui-success-badge-bg: #def3f3;
  --tui-success-badge-fill: #4ac99b;
  --black-80: #000000cc;
  --tui-quill-hover: #06c;
  --df-color-blue-100: #cbd3ff;
  --df-color-blue-400: #6d84ff;
  --tui-hint-background: #122066;
  --tui-hint-text: #eaedff;
  --tui-gray-01: #e0e0e0;
  --df-warning: #fffbe8;
}
