tui-combo-box.select-with-search {
  tui-primitive-textfield {
    font-size: 1rem !important;

    .tui-autofill {
      border: 1px solid var(--tui-link-hover);
      background: var(--tui-hint-text);
      color: var(--tui-link-hover);

      .t-icon {
        color: var(--tui-link-hover);
      }
    }
  }
}
