tui-input-tag {
  &[data-size='l'] {
    height: 3rem !important;
    min-height: 3rem !important;
    max-height: 3rem !important;
    font: var(--tui-font-text-m) !important;

    &.expandable {
      height: auto !important;
    }

    .t-placeholder {
      font: var(--tui-font-text-m) !important;
      height: 1.5rem !important;

      &_raised {
        transform: translateY(-0.625rem) scale(0.85) !important;
      }
    }

    .absolute-wrapper,
    .icons {
      height: 3rem !important;
    }

    [tuiWrapper] > .content {
      padding-left: 1rem !important;
    }

    tui-hosted-dropdown {
      height: inherit;
    }

    .tags {
      padding: 0 0.25rem 0 0 !important;
    }

    .example-text-inner {
      color: var(--tui-text-02) !important;
    }
  }
}
