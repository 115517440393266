div[data-appearance=textfield][tuiwrapper] {
  box-shadow: none !important;
  border-radius: var(--tui-radius-xs);
  background: var(--tui-base-01);
}
div[data-appearance=textfield][tuiwrapper] .t-placeholder {
  color: var(--tui-text-04) !important;
}
div[data-appearance=textfield][tuiwrapper]:after {
  transition: color var(--tui-duration) ease-in-out;
  border-width: 1px;
  color: var(--tui-base-04);
}
div[data-appearance=textfield][tuiwrapper]._invalid:after {
  color: var(--tui-accent-01);
}
div[data-appearance=textfield][tuiwrapper]._focused:after {
  color: var(--tui-primary);
  border-width: 1px;
}
div[data-appearance=textfield][tuiwrapper] .cleaner,
div[data-appearance=textfield][tuiwrapper] .t-icon {
  color: var(--tui-base-04);
}
div[data-appearance=textfield][tuiwrapper] .cleaner:hover,
div[data-appearance=textfield][tuiwrapper] .t-icon:hover {
  color: var(--tui-primary);
}
div[data-appearance=textfield][tuiwrapper] input {
  font: var(--tui-font-text-l) !important;
}

a[tuiLink],
a {
  text-decoration: underline;
  font: var(--tui-font-text-m);
}
a[tuiLink] span,
a span {
  text-decoration: underline;
  font: var(--tui-font-text-m);
}

tui-checkbox-labeled .content,
tui-checkbox .content {
  color: var(--tui-text-03);
}
tui-checkbox-labeled [tuiWrapper][data-appearance=primary],
tui-checkbox [tuiWrapper][data-appearance=primary] {
  border-radius: 0.25rem;
  background: var(--tui-primary);
  color: var(--tui-primary-text);
}
tui-checkbox-labeled [tuiWrapper]._invalid:after,
tui-checkbox [tuiWrapper]._invalid:after {
  color: var(--tui-accent-01) !important;
}
tui-checkbox-labeled [tuiWrapper][data-appearance=outline]:after,
tui-checkbox [tuiWrapper][data-appearance=outline]:after {
  transition: color var(--tui-duration) ease-in-out;
  border-width: 1px;
  color: var(--tui-base-04);
  border-radius: 0.25rem;
}
tui-checkbox-labeled [tuiWrapper][data-appearance=outline][data-state=hovered]:after,
tui-checkbox [tuiWrapper][data-appearance=outline][data-state=hovered]:after {
  color: var(--tui-text-05);
}
tui-checkbox-labeled [tuiWrapper][data-state=disabled],
tui-checkbox [tuiWrapper][data-state=disabled] {
  opacity: 0.4 !important;
}

tui-checkbox-block[data-size=l] [tuiWrapper] .view {
  margin-top: 0.75rem !important;
}
tui-checkbox-block [tuiWrapper]:after {
  color: var(--tui-base-02) !important;
}

tui-dialog > .t-content .t-heading {
  text-align: center;
  margin-top: 1rem;
}
tui-dialog[data-size=s] {
  width: 40vw !important;
}
tui-dialog[data-size=m] {
  width: 55rem !important;
}
tui-dialog[data-size=m] > .t-content {
  padding: 2rem 2rem 3rem 2rem;
}
tui-dialog[data-size=m] > .t-content .t-heading {
  margin-bottom: 2rem !important;
}
tui-dialog[data-size=l] {
  width: 80vw !important;
}
tui-dialog[data-size=fullscreen] > .t-content {
  padding: 1rem !important;
}
tui-dialog.full-size {
  width: 100vw !important;
  border: 0px !important;
  border-radius: 0px !important;
}
tui-dialog .close {
  position: absolute !important;
  top: 1rem !important;
  right: 1rem !important;
}

tui-dialog-host > .overlay {
  backdrop-filter: blur(3px);
}

@media (max-width: 1279px) {
  tui-dialog[data-size=s] {
    width: 50vw !important;
  }
}
@media (max-width: 1023px) {
  tui-dialog[data-size=s] {
    width: 80vw !important;
  }
}
@media (max-width: 767px) {
  tui-dialog > .t-content .t-heading {
    max-width: 80vw;
    margin: 0 auto;
  }
}
button[tuibutton][data-size=xl] .t-wrapper,
button[tuiiconbutton][data-size=xl] .t-wrapper {
  padding: 0 1.5rem !important;
}
button[tuibutton].current-color-fill svg > path,
button[tuiiconbutton].current-color-fill svg > path {
  fill: currentColor !important;
}
button[tuibutton] [tuiWrapper] .t-content,
button[tuiiconbutton] [tuiWrapper] .t-content {
  font: var(--tui-font-text-l);
}
button[tuibutton] [tuiWrapper]._focused:after,
button[tuiiconbutton] [tuiWrapper]._focused:after {
  border-width: 0 !important;
}
button[tuibutton] [tuiWrapper][data-appearance=primary],
button[tuiiconbutton] [tuiWrapper][data-appearance=primary] {
  background: var(--tui-primary);
  color: var(--tui-primary-text);
}
button[tuibutton] [tuiWrapper][data-appearance=primary] svg path,
button[tuiiconbutton] [tuiWrapper][data-appearance=primary] svg path {
  fill: var(--tui-primary-text);
}
button[tuibutton] [tuiWrapper][data-appearance=primary][data-state=hovered],
button[tuiiconbutton] [tuiWrapper][data-appearance=primary][data-state=hovered] {
  background: var(--tui-accent-03);
}
button[tuibutton] [tuiWrapper][data-appearance=primary][data-state=pressed],
button[tuiiconbutton] [tuiWrapper][data-appearance=primary][data-state=pressed] {
  background: var(--tui-accent-04);
}
button[tuibutton] [tuiWrapper][data-appearance=secondary],
button[tuiiconbutton] [tuiWrapper][data-appearance=secondary] {
  background: var(--tui-secondary);
  color: var(--tui-primary);
}
button[tuibutton] [tuiWrapper][data-appearance=secondary] svg path,
button[tuiiconbutton] [tuiWrapper][data-appearance=secondary] svg path {
  fill: var(--tui-primary);
}
button[tuibutton] [tuiWrapper][data-appearance=secondary][data-state=hovered],
button[tuiiconbutton] [tuiWrapper][data-appearance=secondary][data-state=hovered] {
  background: var(--tui-primary);
  color: var(--tui-primary-text);
}
button[tuibutton] [tuiWrapper][data-appearance=secondary][data-state=pressed],
button[tuiiconbutton] [tuiWrapper][data-appearance=secondary][data-state=pressed] {
  background: var(--tui-accent-04);
  color: var(--tui-primary-text);
}
button[tuibutton] [tuiWrapper][data-appearance=outline],
button[tuiiconbutton] [tuiWrapper][data-appearance=outline] {
  color: var(--tui-text-03);
}
button[tuibutton] [tuiWrapper][data-appearance=outline] svg path,
button[tuiiconbutton] [tuiWrapper][data-appearance=outline] svg path {
  fill: var(--tui-text-03);
}
button[tuibutton] [tuiWrapper][data-appearance=outline]:after, button[tuibutton] [tuiWrapper][data-appearance=outline]._focused:after,
button[tuiiconbutton] [tuiWrapper][data-appearance=outline]:after,
button[tuiiconbutton] [tuiWrapper][data-appearance=outline]._focused:after {
  transition: color var(--tui-duration) ease-in-out;
  border-width: 1px !important;
  color: var(--tui-base-04);
}
button[tuibutton] [tuiWrapper][data-appearance=outline][data-state=hovered],
button[tuiiconbutton] [tuiWrapper][data-appearance=outline][data-state=hovered] {
  background: var(--tui-accent-08);
}
button[tuibutton] [tuiWrapper][data-appearance=flat][data-state=hovered],
button[tuiiconbutton] [tuiWrapper][data-appearance=flat][data-state=hovered] {
  background: var(--tui-base-03);
}
button[tuibutton] [tuiWrapper][data-appearance=accent],
button[tuiiconbutton] [tuiWrapper][data-appearance=accent] {
  background-color: var(--tui-accent-13);
}
button[tuibutton] [tuiWrapper][data-appearance=accent] .t-content,
button[tuiiconbutton] [tuiWrapper][data-appearance=accent] .t-content {
  font: var(--tui-font-text-l);
  color: var(--tui-primary) !important;
  font-weight: 500;
}
button[tuibutton] [tuiWrapper][data-appearance=accent] svg path,
button[tuiiconbutton] [tuiWrapper][data-appearance=accent] svg path {
  fill: var(--tui-primary);
}
button[tuibutton] [tuiWrapper][data-appearance=accent]:hover,
button[tuiiconbutton] [tuiWrapper][data-appearance=accent]:hover {
  background-color: var(--tui-accent-13-hover);
}
button[tuibutton] [tuiWrapper][data-state=disabled],
button[tuiiconbutton] [tuiWrapper][data-state=disabled] {
  opacity: 0.6;
}

button.option-checkbox [tuiWrapper] {
  border-radius: 100%;
  position: relative;
}
button.option-checkbox [tuiWrapper] .mark {
  display: none;
}
button.option-checkbox [tuiWrapper] > span:before {
  display: block;
  background-color: white;
  content: "";
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  transition-duration: 300ms;
}
button.option-checkbox [tuiWrapper][data-appearance=primary] > span:before {
  width: 0.5rem;
  height: 0.5rem;
}

@media (max-width: 767px) {
  button[data-size=l] [tuiWrapper] {
    padding: 0 1rem !important;
  }
}
hr {
  background: var(--tui-base-04);
}

.tui-text_body-m-1 {
  font: var(--tui-font-text-l);
  font-weight: 600;
}

.tui-text_body-m-3 {
  font: var(--tui-font-text-l);
}

.tui-text_body-m-4 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.tui-text_body-m-6 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.tui-text_body-m-7 {
  font-size: 0.938rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.tui-text_body-m-8 {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 400;
}

.tui-text_body-m-9 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.tui-text_body-m-10 {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
}

.tui-text_body-m-11 {
  font-size: 0.813rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.tui-text_body-m-12 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
}

.tui-text_body-m-13 {
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.tui-text_body-m-14 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.tui-text_body-m-15 {
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.tui-text_body-m-16 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.tui-text_body-17 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
}

.tui-text_body-18 {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
}

.tui-text-body-s-2 {
  font: var(--tui-font-text-s);
}

.tui-text-body-xs {
  font: var(--tui-font-text-xs);
}

.tui-text-body-xs-2 {
  font: var(--tui-font-text-xs) !important;
}

.labels {
  font: var(--tui-font-text-s);
}

.tui-text_menu-header {
  font: var(--tui-font-heading-7) !important;
  text-decoration: none;
}

.tui-base-01 {
  color: var(--tui-base-01);
}
.tui-base-01 svg path {
  fill: var(--tui-base-01);
}
.tui-base-01-bg {
  background-color: var(--tui-base-01) !important;
}

.tui-base-09 {
  color: var(--tui-base-09);
}
.tui-base-09 svg path {
  fill: var(--tui-base-09);
}
.tui-base-09-bg {
  background-color: var(--tui-base-09) !important;
}

.tui-base-10 {
  color: var(--tui-base-10);
}
.tui-base-10 svg path {
  fill: var(--tui-base-10);
}
.tui-base-10-bg {
  background-color: var(--tui-base-10) !important;
}

.tui-text-01 {
  color: var(--tui-text-01);
}
.tui-text-01 svg path,
.tui-text-01 svg circle {
  fill: var(--tui-text-01);
}
.tui-text-01-bg {
  background-color: var(--tui-text-01) !important;
}

.tui-text-02 {
  color: var(--tui-text-02);
}

.tui-text-03 {
  color: var(--tui-text-03);
}
.tui-text-03 svg path {
  fill: var(--tui-text-03) !important;
}
.tui-text-03-bg {
  background-color: var(--tui-text-03) !important;
}

.tui-text-04 {
  color: var(--tui-text-04);
}
.tui-text-04 svg path {
  fill: var(--tui-text-04) !important;
}
.tui-text-04-bg {
  background-color: var(--tui-text-04) !important;
}
.tui-text-04-border {
  border-color: var(--tui-text-04) !important;
}
.tui-text-04-border-hvr:hover {
  border-color: var(--tui-text-04) !important;
}

.tui-text-05 {
  color: var(--tui-text-05);
}

.tui-base-02 {
  background: var(--tui-base-02);
}
.tui-base-02-border {
  border-color: var(--tui-base-02) !important;
}

.tui-base-03 {
  background: var(--tui-base-03);
}
.tui-base-03-border {
  border-color: var(--tui-base-03) !important;
}

.tui-base-04 {
  background: var(--tui-base-04);
}
.tui-base-04-border {
  border-color: var(--tui-base-04) !important;
}
.tui-base-04-svg svg path {
  fill: var(--tui-base-04) !important;
}

.tui-base-05 {
  background: var(--tui-base-05);
}
.tui-base-05-border {
  border-color: var(--tui-base-05) !important;
}
.tui-base-05-svg svg path {
  fill: var(--tui-base-05) !important;
}

.tui-base-06 {
  color: var(--tui-base-06);
}
.tui-base-06-bg {
  background-color: var(--tui-base-06);
}
.tui-base-06-border {
  border-color: var(--tui-base-06);
}
.tui-base-06 svg path {
  fill: var(--tui-base-06) !important;
}

.tui-primary {
  color: var(--tui-primary) !important;
}
.tui-primary-bg {
  background-color: var(--tui-primary) !important;
}
.tui-primary-border {
  border-color: var(--tui-primary) !important;
}
.tui-primary svg path {
  fill: var(--tui-primary) !important;
}

.tui-secondary {
  color: var(--tui-secondary) !important;
}
.tui-secondary-bg {
  background-color: var(--tui-secondary) !important;
}
.tui-secondary-border {
  border-color: var(--tui-secondary) !important;
}
.tui-secondary svg path {
  fill: var(--tui-p--tui-secondaryrimary) !important;
}

.tui-accent {
  color: var(--tui-accent) !important;
}
.tui-accent svg path {
  fill: var(--tui-accent) !important;
}

.tui-accent-01 {
  color: var(--tui-accent-01) !important;
}
.tui-accent-01 svg path {
  fill: var(--tui-accent-01) !important;
}
.tui-accent-01 [tuiWrapper] .content {
  color: var(--tui-accent-01) !important;
}
.tui-accent-01-bg {
  background-color: var(--tui-accent-01) !important;
}

.tui-accent-02 {
  color: var(--tui-accent-02) !important;
}
.tui-accent-02-bg {
  background-color: var(--tui-accent-02) !important;
}
.tui-accent-02-border {
  border-color: var(--tui-accent-02) !important;
}

.tui-accent-03 {
  color: var(--tui-accent-03) !important;
}
.tui-accent-03-bg {
  background-color: var(--tui-accent-03) !important;
}

.tui-accent-04 {
  color: var(--tui-accent-04) !important;
}
.tui-accent-04-bg {
  background-color: var(--tui-accent-04) !important;
}

.tui-accent-05 {
  color: var(--tui-accent-05) !important;
}
.tui-accent-05 svg path {
  fill: var(--tui-accent-05) !important;
}
.tui-accent-05-bg {
  background-color: var(--tui-accent-05) !important;
}

.tui-accent-07 {
  color: var(--tui-accent-07);
}
.tui-accent-07-bg {
  background-color: var(--tui-accent-07);
}
.tui-accent-07-border {
  border-color: var(--tui-accent-07);
}
.tui-accent-07 svg path {
  fill: var(--tui-accent-07) !important;
}

.tui-accent-08 {
  background-color: var(--tui-accent-08) !important;
}

.tui-accent-09 {
  color: var(--tui-accent-09);
}
.tui-accent-09 svg path {
  fill: var(--tui-accent-09) !important;
}

.tui-accent-10 {
  color: var(--tui-accent-10);
}
.tui-accent-10 svg path {
  fill: var(--tui-accent-10) !important;
}

.tui-accent-11 {
  color: var(--tui-accent-11);
}
.tui-accent-11 svg path {
  fill: var(--tui-accent-11) !important;
}

.tui-accent-12 {
  color: var(--tui-accent-12);
}
.tui-accent-12 svg path {
  fill: var(--tui-accent-12) !important;
}

.tui-accent-13 {
  color: var(--tui-accent-13);
}
.tui-accent-13 svg path {
  fill: var(--tui-accent-13) !important;
}
.tui-accent-13-bg {
  background-color: var(--tui-accent-13) !important;
}
.tui-accent-13-border {
  border-color: var(--tui-accent-13) !important;
}

.tui-accent-14 {
  color: var(--tui-accent-14);
}
.tui-accent-14 svg path {
  fill: var(--tui-accent-14) !important;
}
.tui-accent-14-bg {
  background-color: var(--tui-accent-14);
}

.tui-accent-15 {
  color: var(--tui-accent-15);
}
.tui-accent-15 svg path {
  fill: var(--tui-accent-15) !important;
}
.tui-accent-15-bg {
  background-color: var(--tui-accent-15);
}
.tui-accent-15-border {
  border-color: var(--tui-accent-15) !important;
}

.tui-accent-16 {
  color: var(--tui-accent-16);
}
.tui-accent-16 svg path {
  fill: var(--tui-accent-16) !important;
}
.tui-accent-16-bg {
  background-color: var(--tui-accent-16);
}
.tui-accent-16-border {
  border-color: var(--tui-accent-16) !important;
}

.tui-accent-17 {
  color: var(--tui-accent-17);
}
.tui-accent-17 svg path {
  fill: var(--tui-accent-17) !important;
}
.tui-accent-17-bg {
  background-color: var(--tui-accent-17);
}
.tui-accent-17-border {
  border-color: var(--tui-accent-17) !important;
}

.tui-accent-18 {
  color: var(--tui-accent-18);
}
.tui-accent-18 svg path {
  fill: var(--tui-accent-18) !important;
}
.tui-accent-18-bg {
  background-color: var(--tui-accent-18);
}
.tui-accent-18-border {
  border-color: var(--tui-accent-18) !important;
}

.tui-accent-19 {
  color: var(--tui-accent-19);
}
.tui-accent-19 svg {
  color: var(--tui-accent-19);
}
.tui-accent-19 svg path {
  fill: var(--tui-accent-19) !important;
}
.tui-accent-19-bg {
  background-color: var(--tui-accent-19);
}
.tui-accent-19-border {
  border-color: var(--tui-accent-19) !important;
}

.tui-link-night-hover {
  color: var(--tui-link-night-hover);
}
.tui-link-night-hover svg {
  color: var(--tui-link-night-hover);
}
.tui-link-night-hover svg path {
  fill: var(--tui-link-night-hover) !important;
}
.tui-link-night-hover-bg {
  background-color: var(--tui-link-night-hover);
}
.tui-link-night-hover-border {
  border-color: var(--tui-link-night-hover) !important;
}

.tui-support-25-bg {
  background: var(--tui-support-25);
}

.tui-support-26-bg {
  background: var(--tui-support-26);
}

.tui-support-27-bg {
  background: var(--tui-support-27);
}
.tui-support-27-border {
  border-color: var(--tui-support-27);
}

.tui-gray-01 {
  color: var(--tui-gray-01);
}
.tui-gray-01 svg path {
  fill: var(--tui-gray-01) !important;
}
.tui-gray-01-bg {
  background-color: var(--tui-gray-01);
}
.tui-gray-01-border {
  border-color: var(--tui-gray-01) !important;
}

.icebreaker {
  background-color: var(--tui-accent-01);
}
.icebreaker-text {
  color: var(--tui-accent-01);
}
.icebreaker-border {
  border-color: var(--tui-accent-01);
}
.icebreaker-faded {
  background-color: var(--tui-accent-01-faded);
}
.icebreaker-light {
  background-color: var(--tui-accent-01-light);
}
.icebreaker-lighter {
  background-color: var(--tui-accent-01-lighter);
}

.cheeseburger {
  background-color: var(--tui-accent-07);
}
.cheeseburger-text {
  color: var(--tui-accent-07);
}
.cheeseburger-text-light {
  color: var(--tui-accent-07-light);
}
.cheeseburger-border {
  border-color: var(--tui-accent-07);
}
.cheeseburger-faded {
  background-color: var(--tui-accent-07-faded);
}
.cheeseburger-light {
  background-color: var(--tui-accent-07-light);
}
.cheeseburger-lighter {
  background-color: var(--tui-accent-07-lighter);
}

.main-product {
  background-color: var(--tui-accent-05);
}
.main-product-text {
  color: var(--tui-accent-05);
}
.main-product-border {
  border-color: var(--tui-accent-05);
}
.main-product-faded {
  background-color: var(--tui-accent-05-faded);
}
.main-product-light {
  background-color: var(--tui-accent-05-light);
}
.main-product-lighter {
  background-color: var(--tui-accent-05-lighter);
}

.upsell {
  background-color: var(--tui-accent);
}
.upsell-text {
  color: var(--tui-accent);
}
.upsell-border {
  border-color: var(--tui-accent);
}
.upsell-faded {
  background-color: var(--tui-accent-faded);
}
.upsell-light {
  background-color: var(--tui-accent-light);
}
.upsell-lighter {
  background-color: var(--tui-accent-lighter);
}

.black-80 {
  color: var(--black-80);
}
.black-80 svg path {
  fill: var(--black-80);
}
.black-80-bg {
  background-color: var(--black-80) !important;
}

.bg-tui-base-02 {
  background: var(--tui-base-02);
}

tui-multi-select [data-size=m] {
  height: 3rem !important;
  min-height: 3rem !important;
  max-height: 3rem !important;
  font: var(--tui-font-text-m) !important;
}
tui-multi-select [data-size=m] :not(._label-outside) .tags {
  padding-top: 1.25rem !important;
  height: 3rem;
}
tui-multi-select [data-size=m] .t-placeholder {
  font: var(--tui-font-text-m);
  height: 1.5rem;
  transform: translateY(0.2rem);
}
tui-multi-select [data-size=m] .absolute-wrapper,
tui-multi-select [data-size=m] .icons {
  height: 3rem !important;
}
tui-multi-select [data-size=m] [tuiWrapper] > .content {
  padding-left: 1rem !important;
}
tui-multi-select [data-size=m] tui-hosted-dropdown {
  height: inherit;
}

tui-filter [data-appearance=whiteblock] {
  background: var(--tui-base-01);
  border: solid 1px var(--tui-base-04);
}
tui-filter [data-appearance=whiteblock-active] {
  background: var(--tui-accent-08);
  border: solid 1px var(--tui-primary);
}
tui-filter [data-appearance=whiteblock-active] .content {
  color: var(--tui-primary);
}
tui-filter [data-size=l] .content {
  padding: 0 0.7rem !important;
  font: var(--tui-font-text-s);
}

tui-tooltip {
  width: 1rem !important;
  height: 1rem !important;
}
tui-tooltip svg,
tui-tooltip use {
  color: var(--tui-accent-09);
  width: 1rem !important;
  height: 1rem !important;
}

tui-hint {
  background-color: var(--tui-hint-background) !important;
  color: var(--tui-hint-text) !important;
}

tui-toggle[data-size=l] {
  width: 2.5rem !important;
}
tui-toggle[data-size=l] [tuiWrapper] .toggle {
  width: 3.5rem !important;
  transform: translateX(-1rem) !important;
}
tui-toggle [tuiWrapper] {
  background: var(--tui-base-02);
}
tui-toggle [tuiWrapper]:after {
  color: var(--tui-text-04) !important;
}
tui-toggle [tuiWrapper] .toggle .circle {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
tui-toggle._checked [tuiWrapper] {
  background: var(--tui-primary);
}
tui-toggle._checked [tuiWrapper] .toggle {
  transform: translateX(0rem) !important;
}
tui-toggle._checked [tuiWrapper]:after {
  color: var(--tui-primary) !important;
}

.t-dummy {
  width: 1rem !important;
}

tui-select [data-size=l] {
  height: 3rem !important;
  min-height: 3rem !important;
  max-height: 3rem !important;
  font: var(--tui-font-text-m) !important;
}
tui-select [data-size=l] .t-placeholder {
  font: var(--tui-font-text-m) !important;
  height: 1.5rem !important;
}
tui-select [data-size=l] .t-placeholder_raised {
  transform: translateY(-0.625rem) translateX(-1.5rem) scale(0.85) !important;
}
tui-select [data-size=l] .absolute-wrapper,
tui-select [data-size=l] .t-icons {
  height: 3rem !important;
}
tui-select [data-size=l] [tuiWrapper] > .content {
  padding-left: 1rem !important;
}
tui-select [data-size=l] tui-hosted-dropdown {
  height: inherit;
}
tui-select .t-icons .t-icon {
  color: var(--tui-text-03) !important;
}
tui-select._readonly [tuiWrapper] {
  opacity: 0.7 !important;
}
tui-select._readonly [tuiWrapper]:after {
  color: var(--tui-base-04) !important;
}
tui-select._readonly .t-icons {
  display: none !important;
}

[data-size=xs] .checkmark {
  margin: 0 -0.015rem 0 auto !important;
}

.tui-tabs-vertical {
  height: auto !important;
  flex-direction: column !important;
  position: relative;
  box-shadow: none !important;
}
.tui-tabs-vertical tui-underline {
  display: none !important;
}
.tui-tabs-vertical .vertical-underline {
  position: absolute;
  width: 0.3rem;
  height: 4rem;
  top: 0px;
  left: 0px;
  z-index: 5;
  background: var(--tui-primary);
  transition: 0.3s var(--tui-cubic-bezier);
}
.tui-tabs-vertical a,
.tui-tabs-vertical button {
  height: 4rem;
  padding: 0 1.5rem;
  width: 100%;
  border: 0px !important;
}
.tui-tabs-vertical a span,
.tui-tabs-vertical button span {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  text-decoration: none;
  color: var(--tui-text-01);
  font: var(--tui-font-text-l);
  justify-content: flex-start !important;
  width: 100%;
  border: 0px !important;
}
.tui-tabs-vertical a:hover,
.tui-tabs-vertical button:hover {
  box-shadow: none !important;
  background: var(--tui-base-02);
}
.tui-tabs-vertical a svg path,
.tui-tabs-vertical button svg path {
  fill: var(--tui-text-01);
}
.tui-tabs-vertical a.vertical-active span,
.tui-tabs-vertical button.vertical-active span {
  font-weight: 700;
  color: var(--tui-primary);
}
.tui-tabs-vertical a.vertical-active svg path,
.tui-tabs-vertical button.vertical-active svg path {
  fill: var(--tui-primary);
}

tui-accordion ._has-arrow .header {
  padding: 0.75rem 1.5rem !important;
}
tui-accordion tui-expand > .wrapper > .content {
  padding: 0px 1.5rem 1rem 1.5rem !important;
}
tui-accordion [data-tui-host-size=s] .header {
  padding: 0rem 1rem 0rem 1rem !important;
}

tui-breadcrumbs a {
  text-decoration: none !important;
}
tui-breadcrumbs a:first-child span {
  font: var(--tui-font-text-l) !important;
  font-weight: 600 !important;
  color: var(--tui-text-01);
}
tui-breadcrumbs a span {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  text-decoration: none !important;
  color: var(--tui-primary);
}
tui-breadcrumbs tui-svg {
  color: var(--tui-text-04) !important;
}
tui-breadcrumbs .content {
  max-width: 15rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
}

@media (max-width: 767px) {
  tui-breadcrumbs {
    display: none !important;
  }
}
tui-radio-labeled > label {
  display: inline-flex;
}
tui-radio-labeled > label > tui-radio {
  width: 1.5rem;
  height: 1.5rem;
}
tui-radio-labeled > label > tui-radio > [tuiWrapper] {
  border: 1px solid var(--tui-base-04);
  border-radius: 100%;
}
tui-radio-labeled > label > tui-radio > [tuiWrapper][data-appearance=primary] {
  background: var(--tui-primary);
  color: var(--tui-primary-text);
  display: flex;
  justify-content: center;
  align-items: center;
}

tui-island.tui-island_size_m {
  padding: 2rem 3.5rem !important;
}

@media (max-width: 767px) {
  tui-island.tui-island_size_m {
    padding: 1rem !important;
  }
}
tui-text-area .t-placeholder {
  color: var(--tui-text-04) !important;
}

tui-input-tag[data-size=l] {
  height: 3rem !important;
  min-height: 3rem !important;
  max-height: 3rem !important;
  font: var(--tui-font-text-m) !important;
}
tui-input-tag[data-size=l].expandable {
  height: auto !important;
}
tui-input-tag[data-size=l] .t-placeholder {
  font: var(--tui-font-text-m) !important;
  height: 1.5rem !important;
}
tui-input-tag[data-size=l] .t-placeholder_raised {
  transform: translateY(-0.625rem) scale(0.85) !important;
}
tui-input-tag[data-size=l] .absolute-wrapper,
tui-input-tag[data-size=l] .icons {
  height: 3rem !important;
}
tui-input-tag[data-size=l] [tuiWrapper] > .content {
  padding-left: 1rem !important;
}
tui-input-tag[data-size=l] tui-hosted-dropdown {
  height: inherit;
}
tui-input-tag[data-size=l] .tags {
  padding: 0 0.25rem 0 0 !important;
}
tui-input-tag[data-size=l] .example-text-inner {
  color: var(--tui-text-02) !important;
}

tui-alert-host {
  z-index: 999999;
}
tui-alert-host .t-wrapper {
  margin: auto;
}
tui-alert-host tui-alert {
  margin-left: auto !important;
  margin-right: auto !important;
}

tui-input-files .t-wrapper:after {
  color: var(--tui-primary) !important;
}
tui-input-files .t-wrapper a[tuiLink] {
  color: var(--tui-primary) !important;
}

.t-native {
  -webkit-text-fill-color: inherit !important;
}

tui-push-alert {
  position: relative;
  z-index: 99999999;
}
tui-push-alert tui-push {
  border: 1px solid #52c41a;
  background-color: #eef9e8 !important;
  max-width: 500px !important;
  width: auto !important;
}
tui-push-alert .t-top {
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: rgba(25, 28, 48, 0.8980392157) !important;
}
tui-push-alert .t-link button {
  text-decoration: underline;
  color: #4d69ff;
  font-weight: 500 !important;
  font-size: 14px;
}
tui-push-alert .t-content {
  display: block !important;
}
tui-push-alert .t-content div {
  font-weight: 400;
  font-size: 14px;
  color: rgba(25, 28, 48, 0.8980392157);
}

tui-combo-box.select-with-search tui-primitive-textfield {
  font-size: 1rem !important;
}
tui-combo-box.select-with-search tui-primitive-textfield .tui-autofill {
  border: 1px solid var(--tui-link-hover);
  background: var(--tui-hint-text);
  color: var(--tui-link-hover);
}
tui-combo-box.select-with-search tui-primitive-textfield .tui-autofill .t-icon {
  color: var(--tui-link-hover);
}

.tui-form__row {
  margin-top: 0.5rem;
}

@media (max-width: 767px) {
  .tui-form__multi-field {
    margin-top: 0 !important;
  }
}
@media (max-width: 599px) {
  .tui-form__multi-field.mt-helper {
    margin-top: 0.5rem !important;
  }
}
:root {
  --tui-heading-font: "Poppins";
  --tui-text-font: "Poppins";
  --tui-font-heading: var(--tui-heading-font);
  --tui-font-heading-1: 700 4rem/4.5rem var(--tui-font-heading);
  --tui-font-heading-2: bold 2.25rem/3.5rem var(--tui-font-heading);
  --tui-font-heading-3: bold 2.25rem/2.5rem var(--tui-font-heading);
  --tui-font-heading-4: bold 1.75rem/2rem var(--tui-font-heading);
  --tui-font-heading-5: 600 1.35rem/1.85rem var(--tui-font-heading);
  --tui-font-heading-6: bold 1rem/1.25rem var(--tui-font-heading);
  --tui-font-heading-7: 700 0.625rem/0.75rem var(--tui-font-heading);
  --tui-font-heading-8: 700 1rem/1.5rem var(--tui-font-heading);
  --tui-font-text: var(--tui-text-font);
  --tui-font-text-xl: normal 1.1875rem/1.75rem var(--tui-font-text);
  --tui-font-text-l: normal 1rem/1.5rem var(--tui-font-text);
  --tui-font-text-m: normal 0.9375rem/1.5rem var(--tui-font-text);
  --tui-font-text-s: normal 0.8125rem/1.25rem var(--tui-font-text);
  --tui-font-text-xs: normal 0.6875rem/1rem var(--tui-font-text);
  --tui-radius-xs: 0.5rem;
  --tui-radius-s: 0.625rem;
  --tui-radius-m: 0.75rem;
  --tui-radius-l: 1rem;
  --tui-radius-xl: 1.25rem;
  --tui-height-xs: 1.5rem;
  --tui-height-s: 2rem;
  --tui-height-m: 2.5rem;
  --tui-height-l: 3rem;
  --tui-padding-s: 0.75rem;
  --tui-padding-m: 0.75rem;
  --tui-padding-l: 1rem;
  --tui-duration: 0.3s;
  --tui-duration: 0.3s;
  --tui-shadow-1: 0px 2px 3px 0px #0000001a;
  --tui-disabled-opacity: 0.56;
  --tui-autofill: #fff5c0;
  --tui-base-01: #fff;
  --tui-base-02: #f3f5f6;
  --tui-base-03: #ededed;
  --tui-base-04: #e2e5e9;
  --tui-base-05: #b0b0b0;
  --tui-base-06: #959595;
  --tui-base-07: #808080;
  --tui-base-08: #333;
  --tui-base-09: #000;
  --tui-base-10: #f9f9f9;
  --tui-primary: #2e4fff;
  --tui-primary-hover: #6c86e2;
  --tui-primary-active: #314692;
  --tui-primary-text: #fff;
  --tui-secondary: #ebefff;
  --tui-secondary-hover: #dfe3f3;
  --tui-secondary-active: #d8ddf2;
  --tui-accent: #f0047f;
  --tui-accent-faded: #f3ddea;
  --tui-accent-light: #fa4490;
  --tui-accent-lighter: #fee6f3;
  --tui-accent-01: #e96363;
  --tui-accent-01-faded: #f7e4e4;
  --tui-accent-01-light: #f26d6d;
  --tui-accent-01-lighter: #f7e6e6;
  --tui-accent-02: #ffd24d;
  --tui-accent-03: #292594;
  --tui-accent-04: #3631c5;
  --tui-accent-05: #600df2;
  --tui-accent-05-faded: #e4def6;
  --tui-accent-05-light: #975bff;
  --tui-accent-05-lighter: #f0e7fe;
  --tui-accent-07: #0ac2a3;
  --tui-accent-07-faded: #dcf0ee;
  --tui-accent-07-light: #23d6b7;
  --tui-accent-07-lighter: #e7f9f6;
  --tui-accent-08: #e9f5fc;
  --tui-accent-09: #2092df;
  --tui-accent-10: #1fceff;
  --tui-accent-11: #3cc98c;
  --tui-accent-12: #e743a0;
  --tui-accent-13: #e5e9ff;
  --tui-accent-13-hover: #e5e9ffaa;
  --tui-accent-14: #f45725;
  --tui-accent-15: #38339c;
  --tui-accent-16: #3c389c;
  --tui-accent-17: #ff0000;
  --tui-accent-18: #ffc456;
  --tui-accent-19: #ff467e;
  --tui-accent-29: #efefef;
  --tui-accent-hover: #ff9a94;
  --tui-accent-active: #e7716a;
  --tui-accent-text: #fff;
  --tui-selection: rgba(46, 79, 255, 0.32);
  --tui-focus: rgba(51, 51, 51, 0.64);
  --tui-clear: rgba(0, 0, 0, 0.08);
  --tui-clear-disabled: rgba(0, 0, 0, 0.04);
  --tui-clear-hover: rgba(0, 0, 0, 0.16);
  --tui-clear-active: rgba(0, 0, 0, 0.2);
  --tui-clear-inverse: rgba(255, 255, 255, 0.16);
  --tui-clear-inverse-hover: rgba(255, 255, 255, 0.24);
  --tui-clear-inverse-active: rgba(255, 255, 255, 0.4);
  --tui-error-fill: rgba(244, 87, 37, 1);
  --tui-error-bg: rgba(244, 87, 37, 0.12);
  --tui-error-bg-hover: rgba(244, 87, 37, 0.24);
  --tui-success-fill: rgba(74, 201, 155, 1);
  --tui-success-bg: rgba(74, 201, 155, 0.12);
  --tui-success-bg-hover: rgba(74, 201, 155, 0.24);
  --tui-warning-fill: rgba(255, 199, 0, 1);
  --tui-warning-bg: rgba(255, 199, 0, 0.12);
  --tui-warning-bg-hover: rgba(255, 199, 0, 0.24);
  --tui-info-fill: rgba(112, 182, 246, 1);
  --tui-info-bg: rgba(112, 182, 246, 0.12);
  --tui-info-bg-hover: rgba(112, 182, 246, 0.24);
  --tui-neutral-fill: rgb(121, 129, 140);
  --tui-neutral-bg: rgba(121, 129, 140, 0.12);
  --tui-neutral-bg-hover: rgba(121, 129, 140, 0.24);
  --tui-text-01: #272e55;
  --tui-text-02: #1b1f3ba6;
  --tui-text-03: #0a1f5c;
  --tui-text-04: #bbc0d1;
  --tui-text-05: #6e7191;
  --tui-text-06: #5d74f5;
  --tui-link: #0a1f5c;
  --tui-link-hover: #2e4fff;
  --tui-positive: #3aa981;
  --tui-positive-hover: #7ac5aa;
  --tui-negative: #dd4c1e;
  --tui-negative-hover: #e38163;
  --tui-error-fill-night: rgba(255, 140, 103, 1);
  --tui-error-bg-night: rgba(244, 87, 37, 0.32);
  --tui-error-bg-night-hover: rgba(244, 87, 37, 0.4);
  --tui-success-fill-night: rgb(74, 201, 155);
  --tui-success-bg-night: rgba(74, 201, 155, 0.32);
  --tui-success-bg-night-hover: rgba(74, 201, 155, 0.4);
  --tui-warning-fill-night: rgb(255, 199, 0);
  --tui-warning-bg-night: rgba(255, 199, 0, 0.32);
  --tui-warning-bg-night-hover: rgba(255, 199, 0, 0.4);
  --tui-info-fill-night: rgb(112, 182, 246);
  --tui-info-bg-night: rgba(112, 182, 246, 0.32);
  --tui-info-bg-night-hover: rgba(112, 182, 246, 0.4);
  --tui-neutral-fill-night: rgb(149, 155, 164);
  --tui-neutral-bg-night: rgb(149, 155, 164, 0.32);
  --tui-neutral-bg-night-hover: rgb(149, 155, 164, 0.48);
  --tui-text-01-night: rgba(255, 255, 255, 1);
  --tui-text-02-night: rgba(255, 255, 255, 0.72);
  --tui-text-03-night: rgba(255, 255, 255, 0.6);
  --tui-link-night: #6788ff;
  --tui-link-night-hover: #526ed3;
  --tui-positive-night: #44c596;
  --tui-positive-night-hover: #3aa981;
  --tui-negative-night: #ff8c67;
  --tui-negative-night-hover: #bb593a;
  --tui-support-01: #a8cef1;
  --tui-support-02: #3682db;
  --tui-support-03: #8dda71;
  --tui-support-04: #34b41f;
  --tui-support-05: #e29398;
  --tui-support-06: #b8474e;
  --tui-support-07: #fcc068;
  --tui-support-08: #ff8a00;
  --tui-support-09: #dab3f9;
  --tui-support-10: #7b439e;
  --tui-support-11: #fee797;
  --tui-support-12: #fcbb14;
  --tui-support-13: #ea97c4;
  --tui-support-14: #bd65a4;
  --tui-support-15: #7fd7cc;
  --tui-support-16: #2fad96;
  --tui-support-17: #d4aca2;
  --tui-support-18: #9d6f64;
  --tui-support-19: #d2e9a2;
  --tui-support-20: #aadc42;
  --tui-support-21: #a0c5df;
  --tui-support-22: #3c7ba8;
  --tui-support-23: #f6f8f9;
  --tui-support-24: #d3d6e0;
  --tui-support-25: #f6f8f8ff;
  --tui-support-26: #f8faff;
  --tui-support-27: #eff2fe;
  --tui-support-28: #b8b7d0;
  --tui-support-29: #3934a7;
  --tui-support-30: #5752b4;
  --tui-cubic-bezier: cubic-bezier(0.26, 0.86, 0.09, 0.84);
  --tui-badgepro-text: #c97b26;
  --tui-badgepro-01: #ffe259;
  --tui-badgepro-02: #ffa751;
  --tui-oxford-bg: #133464;
  --credits-bg: rgba(255, 225, 188, 0.32);
  --credits-text: #f9c27e;
  --tui-info-bg-2: rgba(227, 241, 254, 1);
  --tui-error-bg-2: rgba(255, 234, 234, 1);
  --tui-error-text: rgba(255, 86, 86, 1);
  --tui-warning-badge-bg: #fff8e0;
  --tui-warning-badge-fill: var(--tui-accent-02);
  --tui-success-badge-bg: #def3f3;
  --tui-success-badge-fill: #4ac99b;
  --black-80: #000000cc;
  --tui-quill-hover: #06c;
  --df-color-blue-100: #cbd3ff;
  --df-color-blue-400: #6d84ff;
  --tui-hint-background: #122066;
  --tui-hint-text: #eaedff;
  --tui-gray-01: #e0e0e0;
  --df-warning: #fffbe8;
}